import React, { useEffect, useRef, useState } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import MolGrid from "../../components/Grid/MolGrid";
import { useNavigate } from "react-router-dom";
import CenterModel from "../../components/ui/centerModel/CenterModel";
import EmailManagementFormData, { EmailManagementGridConfig } from "./EmailManagementForm.data";
import { useGetEmailcampaignListMutation, useGetRecipientDropdownQuery, useLazyDeleteEmailCampaignByIdQuery, useLazyDuplicateEmailCampaignByIdQuery, useLazyGetRecipientsDropdownQuery } from "../../app/services/EmailCampaign/EmailCampaignApi";
import { encryptAESID } from "../../utils/Encryption";
import { PreviewCampaignPopUp } from "./component/PreviewCampaignPopUp.js";
import SwalAlert from "../../services/swalService/SwalService.js";

const EmailManagementForm = (props) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emailCampaignGridRef = useRef();
  const emailFromRef = useRef();
  const [campaignId, setCampaignId] = useState(null);
  const { success, confirm } = SwalAlert()
  const [searchFormData, setSearchForm] = useState(EmailManagementFormData);
  const [emailCampaignList, setEmailCampaignList] = useState(null);
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    campaignName: null,
    fromDate: null,
    toDate: null,
    recipients: null,
    totalCount: 0,
  });

  useEffect(() => {
    getList();
    getDropDown();
  }, [])

  const [getCampaignList,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: campaignListResponse },
  ] = useGetEmailcampaignListMutation();

  const [duplicateEmailCampaignById,
    { isSuccess: isSuccessDuplicateEmailCampaignById,
      isLoading: isLoadingDuplicateEmailCampaignById,
      data: DuplicateEmailCampaignByIdResponse },
  ] = useLazyDuplicateEmailCampaignByIdQuery();

  const [deleteEmailCampaignById,
    { isSuccess: isSuccessDeleteEmailCampaignById,
      isLoading: isLoadingDeleteEmailCampaignById,
      data: DeleteEmailCampaignByIdResponse },
  ] = useLazyDeleteEmailCampaignByIdQuery();

  useEffect(() => {
    if (!isLoadingDuplicateEmailCampaignById && isSuccessDuplicateEmailCampaignById && DuplicateEmailCampaignByIdResponse) {
      getList();
      success("Campaign Duplicate Successfully")
    }
  }, [isLoadingDuplicateEmailCampaignById, isSuccessDuplicateEmailCampaignById, DuplicateEmailCampaignByIdResponse]);

  useEffect(() => {
    if (!isLoading && isSuccess && campaignListResponse) {
      setPaginationRequest({ ...paginationRequest, totalCount: campaignListResponse.totalCount })
      setEmailCampaignList(campaignListResponse.itemList)
    }
  }, [isLoading, isSuccess, campaignListResponse]);

  useEffect(() => {
    if (!isLoadingDeleteEmailCampaignById && isSuccessDeleteEmailCampaignById && DeleteEmailCampaignByIdResponse) {
      success("Email campaign delete successfully");
      getList();
    }
  }, [isLoadingDeleteEmailCampaignById, isSuccessDeleteEmailCampaignById, DeleteEmailCampaignByIdResponse]);

  const [getDropDown,
    { isSuccess: isSuccessDropDown,
      isLoading: isLoadingDropDown,
      data: DropDownData },
  ] = useLazyGetRecipientsDropdownQuery();

  useEffect(() => {
    if (!isLoadingDropDown && isSuccessDropDown && DropDownData) {
      let options = DropDownData.map((obj) => ({
        value: obj.id,
        label: obj.name
      }))

      let formData = { ...searchFormData }
      formData.formFields.forEach(field => {
        if (field.id === "Recipients") {
          field.fieldSetting.options = options;
        }
      });
      setSearchForm(formData)

    }
  }, [isLoadingDropDown, isSuccessDropDown, DropDownData]);


  const getList = (rData) => {
    const request = { ...paginationRequest, ...rData }
    getCampaignList(request)
  }
  const handlePageChange = (newPage) => {
    setPaginationRequest(previous => {
      const tempPagination = { ...previous, pageNo: newPage.pageNumber, pageSize: newPage.pageSize }
      getList(tempPagination)
      return tempPagination
    })
  }
  const handleSearch = () => {

    if (emailFromRef) {
      let formData = emailFromRef.current.getFormData()
      let data = {
        campaignName: formData.campaignName,
        fromDate: formData.fromDate,
        toDate: formData.toDate,
        recipients: formData.recipients?.value,
        pageNo: 1
      }
      setPaginationRequest({ ...paginationRequest, ...data })
      getList(data);
    }
  }

  // const handleFormDataChange = (formData) => { };

  const handleAddEditCampaign = () => {
    navigate("/AddCampaign");
  };

  const HandleDeleteRecord = (emailCampaignId) => {
    const id = emailCampaignId;
    confirm("Are you sure", "", "Delete", "Cancel").then((confirmed) => {
      if (confirmed) {
        deleteEmailCampaignById(id);
      }
    });
  };

  const handleCustomActionClick = (rowData, buttonName) => {

    if (buttonName === "Edit") {
      navigate(`/EditCampaign/${encryptAESID(rowData.emailCampaignId)}`)
    }
    else if (buttonName === "Preview") {
      setCampaignId(rowData.emailCampaignId)
      openModal()
    }
    else if (buttonName === "Duplicate") {
      setCampaignId(rowData.emailCampaignId)
      duplicateEmailCampaignById(rowData.emailCampaignId.toString())
    }
    else if (buttonName === "Approve") {
      setCampaignId(rowData.emailCampaignId)
    }
    else if (buttonName === "Delete") {
      setCampaignId(rowData.emailCampaignId)
      HandleDeleteRecord(rowData.emailCampaignId.toString())
    }
  };

  const actionHandler = {
    "CUSTOM_ACTION_CLICK": handleCustomActionClick
  };

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  }


  return (
    <div className="form-field-sec">
      <div className="row align-items-center">
        <FormCreator
          ref={emailFromRef}
          {...searchFormData}
        // onFormDataUpdate={handleFormDataChange}
        ></FormCreator>

        <div className="col-xxl-2 col-xl-2 col-md-2 d-flex justify-content-end search-btn">
          <Buttons
            buttonTypeClassName="theme-btn"
            buttonText="Search"
            onClick={handleSearch}
          // onClick={handleLogin}
          // isLoading={loginLoading}
          />

        </div>
        <div className="button-part d-flex justify-content-end mt-4">
          <Buttons
            buttonTypeClassName="outline-btn"
            buttonText="+ Add New Campaign"
            onClick={handleAddEditCampaign}
          // isLoading={loginLoading}
          />
        </div>
        <div className="card-section mt-4">
          <MolGrid
            ref={emailCampaignGridRef}
            configuration={EmailManagementGridConfig}
            dataSource={emailCampaignList}
            allowPagination={true}
            isLoading={isLoading || isLoadingDuplicateEmailCampaignById}
            pagination={paginationRequest}
            onPageChange={handlePageChange}
            onActionChange={actionHandler}
          // onPageChange={handlePageChange}
          />
        </div>
      </div>
      {isModalOpen &&
        <CenterModel
          modelTitle={'Preview'}
          show={"Preview"}
          handleClose={openModal}
          onUpload={openModal}
        >
          <><PreviewCampaignPopUp
            campaignId={campaignId}
          /></>
        </CenterModel>
      }

    </div>
  );
};

export default EmailManagementForm;


// const tableData = [
//   {
//     campaignName: "Singapore Womans Award Dinner",
//     recipients: "Member, Affiliate",
//     status: "Draft",
//     date: "18 Jan 2024",
//     time: "10:00AM",
//     isActive: true,
//   },
//   {
//     campaignName: "AAAA Womans Award Dinner",
//     recipients: "Member, Affiliate",
//     status: "Draft",
//     date: "18 Jan 2024",
//     time: "10:00AM",
//     isActive: true,
//   },
// ];