import React, { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FormCreator from '../../components/Forms/FormCreator'
import { corporateFormData } from './data/CorporateMemberTable.Data';
import Buttons from '../../components/ui/button/Buttons';
import {
    useLazyGetAllCorporateInformationDetailsByIdQuery,
    useLazyGetAllDropdownsForCorporateMemberQuery,
    useRejectCorporateMemberApplicationMutation,
    useSentPaymentLinkCorporateMemberMutation,
    useUpdateCorporateMemberDataByIdMutation
} from '../../app/services/Corporate/CorporateTaskListApi';
import { setAllDropDownField } from './utils/setAllDropDownField';
import { useParams } from 'react-router-dom';
import { decryptAESID } from '../../utils/Encryption';
import {
    setFieldParameter,
    setPricingDetailsField,
    forUserCreatedByAdmin
} from './utils/setEditFieldsUtils';
import SwalAlert from '../../services/swalService/SwalService';
import CenterModel from '../../components/ui/centerModel/CenterModel';
import ModuleLoader from '../../components/ui/moduleLoader/ModuleLoader';
import { saveAs } from 'file-saver';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../app/services/Common/CommonApi';
import { useGetMembershipPricingForMembershipTypeIdMutation, useSaveIndividualMemberClarificationDetailsMutation } from '../../app/services/Individual/IndividualTaskListApi';
const TinyEditor = lazy(() => import('../../components/ui/inputs/TinyEditor/TinyEditor'));


const CorporateMemberForm = forwardRef((props, ref) => {
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false).toString() : 0;
    const { confirm, error, success } = SwalAlert();
    const formRef = useRef();
    const pricingRef = useRef(null);
    const [formData, SetFormData] = useState(corporateFormData);
    const [isModalOpenForCF, setIsModalOpenForCF] = useState(false);
    const [rejectComments, setRejectComments] = useState("");
    const [CFComments, setCFComments] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSendPaymentLink, setIsSendPaymentLink] = useState(false);
    const [isUpdateTaskDetailsLink, setIsUpdateTaskDetailsLink] = useState("");
    const [membershipPricingDetails, setMembershipPricingDetails] = useState({
        membershipTypeId: 0,
        membershipTenureId: 0
    });
    const [acraFileName, setACRAFileName] = useState(null);
    const pricingInitialStateRef = useRef({});

    // API hooks to fetch data and handle mutations
    const [getAllDropDowns, { data: dropdownResponse, isSuccess: isSuccess, isLoading: isLoading, }] = useLazyGetAllDropdownsForCorporateMemberQuery();
    const [uploadPDF, { data: fileUploadData, isSuccess: isUploadSuccess, isLoading: isUploadLoading }] = useUploadPDFMutation();
    const [getMemberDetailsByID, { data: memberDetails, isSuccess: isSuccessGetByID, isLoading: isLoadingGetById, }] = useLazyGetAllCorporateInformationDetailsByIdQuery();
    const [updateMemberData, { data: updatedMemberDataResponse, isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate, }] = useUpdateCorporateMemberDataByIdMutation();
    const [rejectApplication, { data: rejectApplicationResponse, isSuccess: IsRejected, isLoading: IsRejectedLoading, }] = useRejectCorporateMemberApplicationMutation();
    const [sendPaymentLink, { data: paymentResponse, isSuccess: isPaymentSuccess, isLoading: IsPaymentLoading, }] = useSentPaymentLinkCorporateMemberMutation();
    const [getPricing, { data: pricingResponse, isSuccess: isSuccessPrice, isLoading: isLoadingPrice, }] = useGetMembershipPricingForMembershipTypeIdMutation();
    const [sendClearfication, { data: CFResponse, isSuccess: isCFSuccess, isLoading: IsCFLoading, }] = useSaveIndividualMemberClarificationDetailsMutation();
    const [getPDF, { isSuccess: isSuccessGetPDF, isLoading: isLoadingGetPDF, data: getPDFData }] = useLazyGetPDFQuery();

    // Expose the form data retrieval method to parent components using the forwarded ref
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const formResponse = formRef.current.getFormData();
                return formResponse;
            }
            return null;
        }
    }));

    // Effect to set dropdown fields once data is loaded
    useEffect(() => {
        if (!isLoading && isSuccess && dropdownResponse) {
            setAllDropDownField(dropdownResponse)
        }
    }, [isLoading, isSuccess, dropdownResponse])

    // Effect to set form fields and pricing fields once member details are loaded
    useEffect(() => {
        if (!isLoadingGetById && isSuccessGetByID && memberDetails) {
            let updateForm = forUserCreatedByAdmin(memberDetails.createdName, formData);
            SetFormData(updateForm);
            setFieldParameter(memberDetails)
            setACRAFileName(memberDetails?.acraBizFileName)
            setMembershipPricingDetails({ ...membershipPricingDetails, membershipTypeId: memberDetails?.membershipTypeId, membershipTenureId: memberDetails?.membershipTenureId })
        }
    }, [isLoadingGetById, isSuccessGetByID, memberDetails])

    // Effect to show success message after member data is updated
    useEffect(() => {
        if (!isLoadingUpdate && isSuccessUpdate && updatedMemberDataResponse && isUpdateTaskDetailsLink === "isUpdated") {
            if (isUpdateTaskDetailsLink === "isUpdated" && isSendPaymentLink) {
                setIsUpdateTaskDetailsLink("");
            }
            else {
                success("", "Application Updated Successfully ");
            }
        }
    }, [isLoadingUpdate, isSuccessUpdate, updatedMemberDataResponse, isSendPaymentLink])

    // Effect to show success message after application is rejected
    useEffect(() => {
        if (!IsRejectedLoading && IsRejected && rejectApplicationResponse) {
            success("", "Application Rejected Successfully ")
            // setIsModalOpen(false);
        }
    }, [IsRejectedLoading, IsRejected, rejectApplicationResponse])

    // Effect to show success message after payment link is sent
    useEffect(() => {
        if (!IsPaymentLoading && isPaymentSuccess && paymentResponse) {
            success("", "Payment Link Sent To Registered Email Successfully ");
            setIsModalOpen(false);
            setIsSendPaymentLink(false);
            // window.location.reload();
        }
    }, [IsPaymentLoading, isPaymentSuccess, paymentResponse])

    // Effect to set pricing details field once pricing response is loaded
    useEffect(() => {
        if (!isLoadingPrice && isSuccessPrice && pricingResponse) {
            const response = setPricingDetailsField(pricingResponse, memberDetails.membershipTenureId);
            response.initialState.membershipTenureId = membershipPricingDetails.membershipTenureId;
            response.initialState.membershipTypeId = membershipPricingDetails.membershipTypeId;
            SetFormData(previousState => ({
                ...previousState,
                initialState: response.initialState
            }));
            pricingInitialStateRef.current = {
                ...response.initialState
            };

        }
    }, [isLoadingPrice, isSuccessPrice, pricingResponse]);

    useEffect(() => {
        if (!IsCFLoading && isCFSuccess && CFResponse) {
            success("", "Clarification Sent Successfully")
            setIsModalOpenForCF(false)
            setCFComments('');
        }
    }, [IsCFLoading, isCFSuccess, CFResponse])

    // Initial data fetch for dropdowns and member details
    useEffect(() => {
        getAllDropDowns()
        if (editID) {
            getMemberDetailsByID(editID);
        }
    }, [])

    // Effect to set pricing field based on membership details
    useEffect(() => {
        if (membershipPricingDetails.membershipTypeId > 0 || membershipPricingDetails.membershipTenureId > 0) {
            setPricingField(membershipPricingDetails.membershipTypeId, membershipPricingDetails.membershipTenureId)
        }
    }, [membershipPricingDetails.membershipTypeId, membershipPricingDetails.membershipTenureId])

    // Handle form field change, specifically for file upload
    const handleFormFieldChange = (dataField, value) => {
        if ((dataField === "acraFile") && value) {
            handleOnChangeFileUpload(dataField, value)
        }
    };

    // Handle file upload change and call API to upload PDF
    const handleOnChangeFileUpload = (dataField, data) => {
        let pathName = dataField === "acraFile" && "ACRA_FILE";
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", pathName);
        uploadPDF(formData);
    }
    const handleClearfication = () => {
        if (CFComments === "" || CFComments === undefined || CFComments === null) {
            return error("The comments field for clarification the application is mandatory and must be filled out.", "error")
        }
        let request = {
            IndividualMembersId: editID,
            ClarificationDetails: CFComments,
            IsCorporate: true,
            IsAcademic: false
        }
        confirm("Clarification", "Are you sure you want to send the clarification?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    sendClearfication(request)
                }
            });
    }

    // Handle update of member data and call API to update
    const handleUpdate = (formData) => {
        let formResponse = formRef.current.getFormData() || formData;
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsUpdateTaskDetailsLink("isUpdated");
        const request = {
            CorporateMembersId: editID || 0,
            MembershipTypeId: formResponse?.membershipTypeId?.value || formResponse?.membershipTypeId || null,
            MembershipTenureId: formResponse?.membershipTenureId?.value || formResponse?.membershipTenureId || null,
            StartDate: formResponse?.subscriptionStartDate || null,
            UNENo: formResponse?.uenNo || null,
            AddressLine1: formResponse?.addressLine1 || null,
            AddressLine2: formResponse?.addressLine2 || null,
            AddressLine3: formResponse?.addressLine3 || null,
            ACRABizFileName: fileUploadData?.fileName || formResponse?.acraFile,
            IndustryCodeId: formResponse?.industryCode || null,
            PostalCode: formResponse?.postalCode || null,
            OrganisationName: formResponse?.nameOfOrgACRA || null,
            IsStartMemberShip: formResponse?.isStartMembershipOn || false,
            StartMemberShipOn: formResponse?.startMembershipDate || null,
            PaymentTermsId: formResponse?.paymentTerms?.value || formResponse?.paymentTerms || null,
            ComplimentaryCorporateMembership: formResponse?.complimentary || null,
            Commentstomember: formResponse?.commentsToMember || null,
            InternalNotes: formResponse?.internalNotes || null,
        }
        updateMemberData(request);
    }

    // Open and close modal for rejecting application
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    // Set pricing field based on membership type and tenure
    const setPricingField = (memberType, memberTenure) => {
        let request = {
            MembershipTypeId: memberType,
            MembershipTenureId: memberTenure,
        }
        getPricing(request);
    }

    // Handle rejection of application and call API to reject
    const handleReject = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        const request = {
            CorporateMembersId: editID,
            IsRejected: true,
            SendRejectionReason: rejectComments,
        }
        confirm("Reject Application", "Are you sure you want to reject the application?", "Yes", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }

    // Handle sending payment link and call API to send link
    const handleSentPaymentLink = () => {
        let formResponse = formRef.current.getFormData();
        if (!formResponse) {
            return error("Please fill all mandatory fields.", "")
        }
        setIsSendPaymentLink(true);
        handleUpdate(formResponse)
        let request = {
            CorporateMembersId: Number(editID),
            IsSentPaymentLink: true,
            PaymentTermId: 1,
            IsStartMemberShip: formResponse?.isStartMembershipOn || null,
            StartMemberShipOn: formResponse?.startMembershipDate || null,
            MembershipTypeId: formResponse.membershipTypeId,
            MembershipTenureId: formResponse.membershipTenureId
        }
        sendPaymentLink(request);
    }

    // Handle clearing form fields
    const handleClear = () => {
        const updatedForm = { ...formData };
        updatedForm.initialState.acraFile = null;
        SetFormData(updatedForm);
    }

    // Handle file download
    const handleDownload = async () => {
        let request = {
            type: "ACRA_FILE",
            fileName: fileUploadData?.fileName || acraFileName,
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    // Handle dropdown change for membership type and tenure
    const handleDropdownChange = (data, dataField) => {
        const fields = ["membershipTenureId", "membershipTypeId"];
        if (fields.includes(dataField)) {
            setMembershipPricingDetails({
                ...membershipPricingDetails,
                [dataField]: data?.value || 0
            });
        }
    };

    const openCFModal = () => {
        setIsModalOpenForCF(!isModalOpenForCF);
    }

    // Form action handler to manage different form actions
    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        DDL_CHANGED: handleDropdownChange
    }
    const scrollToRef = () => {
        if (pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    return (
        <React.Fragment>
            <div className='row gx-5 bg-light tasklist-form'>
                {(isSuccess && isSuccessGetByID)
                    ? <FormCreator
                        ref={formRef} {...formData}
                        initialState={pricingInitialStateRef.current}
                        onActionChange={formActionHandler}
                        onFormFieldValueChange={handleFormFieldChange}
                    />
                    : <ModuleLoader />
                }
            </div>
            <div className='row mt-4'>
                <div className='col-lg-12 d-flex justify-content-between'>
                    <Buttons
                        buttonText="Reject Application"
                        buttonTypeClassName="red-btn"
                        onClick={openModal}
                    />
                    <Buttons
                        buttonText="Send Clarification"
                        buttonTypeClassName="outline-btn"
                        onClick={openCFModal}
                    />
                    <Buttons
                        buttonText="Update Application"
                        buttonTypeClassName="outline-btn"
                        onClick={handleUpdate}
                    />
                    <Buttons
                        buttonText="Send Payment Link"
                        isLoading={IsPaymentLoading}
                        buttonTypeClassName="theme-btn"
                        onClick={handleSentPaymentLink}
                    />
                </div>
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <TinyEditor
                            onTinyMceEditorChange={(e) => setRejectComments(e)}
                        />
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsRejectedLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleReject}
                        />
                    </Suspense>
                </CenterModel>
            }
            {isModalOpenForCF &&
                <CenterModel
                    modelTitle={'Send Clarification'}
                    show={"test"}
                    handleClose={openCFModal}
                    onUpload={openCFModal}
                >
                    <Suspense fallback={<div>Loading Editor...</div>}>
                        <TinyEditor
                            onTinyMceEditorChange={(e) => setCFComments(e)}
                        />
                        <Buttons
                            buttonText="Send Email"
                            isLoading={IsCFLoading}
                            buttonTypeClassName="outline-btn mt-4"
                            onClick={handleClearfication}
                        />
                    </Suspense>
                </CenterModel>
            }
        </React.Fragment>
    )

})

export default CorporateMemberForm
