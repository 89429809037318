import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { academicFormData } from "../data/AcademicMemberTable.Data";
import { useDebugValue } from "react";

export const setFieldParameter = (apiResponse) => {
    academicFormData.initialState.instituteName = apiResponse.organisationName;
    academicFormData.initialState.uenNo = apiResponse.uenNo;
    academicFormData.initialState.institutionAddressLine1 = apiResponse.addressLine1;
    academicFormData.initialState.institutionAddressLine2 = apiResponse.addressLine2;
    academicFormData.initialState.institutionAddressLine3 = apiResponse.addressLine3;
    academicFormData.initialState.postalCode = apiResponse.postalCode;
    academicFormData.initialState.aCRABizFile = apiResponse.acraBizFileName;
    academicFormData.initialState.membershipTypeId = apiResponse.membershipTypeId;
    academicFormData.initialState.billingCategory = apiResponse.academicMemberBillingCategoryId;
    academicFormData.initialState.updatedBy = apiResponse.nameOfContactPerson;
    academicFormData.initialState.subscriptionStartDate = apiResponse.subscriptionStartDate;
    academicFormData.initialState.subscriptionEndDate = apiResponse.subscriptionEndDate;
    academicFormData.initialState.lastUpdatedDate = apiResponse.updatedDate;
    academicFormData.initialState.isStartMembershipOn = apiResponse.isStartMemberShip;
    academicFormData.initialState.startMembershipDate = apiResponse.startMembershipOn;
    academicFormData.initialState.paymentTerms = apiResponse.paymentTermsId || 1;
    academicFormData.initialState.membershipTenureId = apiResponse.membershipTenureId;
    academicFormData.initialState.complimentary = apiResponse.complimentaryIndividualMembership;
    academicFormData.initialState.comment = apiResponse.commentstomember;
    academicFormData.initialState.internalNote = apiResponse.internalNotes;

    const navigatePathIndex = academicFormData.formFields.findIndex(field => field.id === "navigatePath");
    if (navigatePathIndex !== -1) {
        const updatedFormData = { ...academicFormData };
        // Update the containerCss value for periodTo field
        updatedFormData.formFields[navigatePathIndex].fieldSetting.identifyNumber = apiResponse.academicMembersId;
    }
};

export const setPricingDetailsField = (apiResponse, memberType) => {
    academicFormData.initialState.entrenceFee = apiResponse?.applicationFee || 0;
    academicFormData.initialState.annualFee = apiResponse?.subTotal || 0; //apiResponse.membershipTenureId === 1 ? apiResponse.firstYearFee || 0 : apiResponse.secondYearFee || 0;
    academicFormData.initialState.complimentary = apiResponse.complimentaryindividualMembership;
    return academicFormData
}

export const forUserCreatedByAdmin = (dataField, formData) => {
    const addressLineIndex = formData.formFields.findIndex(field => field.id === "institutionAddressLine1");
    const acraBizFileIndex = formData.formFields.findIndex(field => field.id === "aCRABizFile");

    var isAdmin = dataField ? dataField.includes("ADM") : false;

    const newValidationValue = isAdmin ? [] : [{ type: "require" }];
    const updatedFormData = { ...formData };
    if (addressLineIndex !== -1) {
        updatedFormData.formFields[addressLineIndex].validation = newValidationValue;
    }
    if (acraBizFileIndex !== -1) {
        updatedFormData.formFields[acraBizFileIndex].validation = newValidationValue;
    }    
    return { ...updatedFormData };
}
