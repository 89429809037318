import { FormFieldTypes } from "../../../data/formFieldType";

export const EditCampaignFormData = {
  name: "Email Campaign From",
  initialState: {
    subject: null,
    message: null,
    attachment: null,
    send: 'SendLater',
    sendDate: null,
    sendTime: null,
    campaignName: null,
    recipientsByMembershipType: false,
    aispOrdinaryMember: false,
    path1: false,
    path2: false,
    path3: false,
    associateMember: false,
    affiliate: false,
    avip: false,
    fellow: false,
    cppStartUp: false,
    cppsme: false,
    cpp: false,
    cpp100: false,
    academic: false,
    recipientsByGender: false,
    recipientsByJobTitle: false,
    recipientsByGenderSelect: null,
    recipientsByJobTitleSelect: null,
    recipientsByNationality: false,
    recipientsByIndustry: false,
    recipientsByNationalitySelect: null,
    recipientsByIndustrySelect: null,
    recipientsByTagging: false,
    recipientsByTaggingSelect: null,
    recipientsByStatus: false,
    recipientsByStatusSelect: null,
    recipientsByRace: false,
    recipientsByRaceSelect: null,
    recipientsByResidenceStatus: false,
    recipientsByResidenceStatusSelect: null,
    includeEmail: null,
    excludeEmail: null,
    recipientsByCampaignType: false,
    recipientsByCampaignTypeSelect: null
  },
  formFields: [
    {
      id: "subject",
      lable: "Subject",
      Field_Name: "subject",
      fieldType: FormFieldTypes.LOWERCASEINPUT,
      dataField: "subject",
      isStar: true,
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-10 col-xl-10 col-md-10 mb-3 ",
      },
    },
    // {
    //   id: "message",
    //   lable: "Message",
    //   Field_Name: "message",
    //   fieldType: FormFieldTypes.TINYEDITOR,
    //   dataField: "message",
    //   isStar: true,
    //   fieldSetting: {
    //     placeholder: "",
    //     allowSpace: true,
    //   },
    //   // validation: [{ type: "require" }],
    //   style: {
    //     containerCss: "col-xxl-10 col-xl-10 col-md-10 mb-4",
    //   },
    // },
    {
      id: "message",
      lable: "Message",
      Field_Name: "message",
      fieldType: FormFieldTypes.CKEDITOR,
      dataField: "message",
      fieldSetting: {
        allowSpace: true,
      },
      validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-10 col-xl-10 col-md-10 mb-4",
      },
    },
    {
      id: "attachment",
      lable: 'Email Attachment (Please upload in pdf/jpg format max 10 mb)',
      Field_Name: "",
      fieldType: FormFieldTypes.FILE,
      dataField: "attachment",
      fieldSetting: {
        acceptedFiles: '.jpg, .pdf',
        maxFileSizeInKB: 10240, // 10MB
        isDownloadable: true,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-10 col-xl-10 col-md-10 mb-4",
      },
    },

    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  col-12",
      },
    },
    {
      id: "title2",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "Schedule",
      },
      style: {
        containerCss: "col-12 mb-3 mt-0 text-left big-title-theme",
      },
    },
    {
      id: "send",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.RADIOBUTTON,
      dataField: "send",
      fieldSetting: {
        options: [
          { label: "Send Now", value: "SendNow", isDisable: false, optionClass: "" },
          { label: "Send Later", value: "SendLater", isDisable: false, optionClass: "", },
        ],
      },
      validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-10 col-xl-10 col-md-10 mb-3 break-radio",
      },
    },

    {
      id: "date",
      lable: "Date",
      Field_Name: "date",
      fieldType: FormFieldTypes.DATEPICKER,
      dataField: "sendDate",
      fieldSetting: {
        isDisable: false,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-4 col-xl-4 col-md-3",
      },
    },
    {
      id: "time",
      lable: "Time",
      Field_Name: "time",
      fieldType: FormFieldTypes.TIME,
      dataField: "sendTime",
      fieldSetting: {
        isDisable: false,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-3 col-xl-3 col-md-3 mb-4",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  col-12",
      },
    },
    {
      id: "title",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "Email Setup",
      },
      style: {
        containerCss: "col-12 mb-3 mt-0 text-left big-title-theme",
      },
    },
    {
      id: "campaignName",
      lable: "Campaign Name",
      Field_Name: "campaignName",
      fieldType: FormFieldTypes.LOWERCASEINPUT,
      dataField: "campaignName",
      isStar: true,
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-8 col-xl-8 col-md-12",
      },
    },
    {
      id: "recipientsByCampaignTypeSelect",
      lable: "Campaign Type",
      Field_Name: "recipientsByCampaignTypeSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByCampaignTypeSelect",
      fieldSetting: {
        //isMultiSelect: true,
        options: []
      },
      //  validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-4 col-xl-4 col-md-12",
      },
    },
    // ------------------------------------------- Checkbox Start -------------------------------------------
    {
      id: "recipientsByMembershipType",
      lable: "Recipients By Membership Type",
      Field_Name: "recipientsByMembershipType",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByMembershipType",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-10 main-checkbox mt-4",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "title1",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "Individual",
      },
      style: {
        containerCss:
          "col-10 mb-0 mt-0 text-left mx-auto sub-section-title ml-5",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "aispOrdinaryMember",
      lable: "AISP Ordinary Member (0)",
      Field_Name: "aISPOrdinaryMember",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "aispOrdinaryMember",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "path1",
      lable: "Path 1 ()",
      Field_Name: "path1",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "path1",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 inner-checkbox-left-space",
      },
    },
    {
      id: "path2",
      lable: "Path 2 (0)",
      Field_Name: "path2",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "path2",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 inner-checkbox-left-space",
      },
    },
    {
      id: "path3",
      lable: "Path 3 (0)",
      Field_Name: "path3",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "path3",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 inner-checkbox-left-space",
      },
    },
    {
      id: "associateMember",
      lable: "Associate Member (0)",
      Field_Name: "associateMember",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "associateMember",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "affiliate",
      lable: "Affiliate (0)",
      Field_Name: "affiliate",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "affiliate",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "avip",
      lable: "AVIP",
      Field_Name: "avip",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "avip",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "fellow",
      lable: "Fellow (0)",
      Field_Name: "Fellow",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "fellow",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "title3",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "Corporate",
      },
      style: {
        containerCss:
          "col-10 mb-0 mt-0 text-left mx-auto sub-section-title ml-5",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "cppStartUp",
      lable: "CPP (100)",
      Field_Name: "cPP100",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "cppStartUp",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "cppsme",
      lable: "CPP Lite (10)",
      Field_Name: "cPPLite10",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "cppsme",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5  main-checkbox inner-checkbox-left-space",
      },
    },
    // {
    //   id: "startUp",
    //   lable: "Start Up (20)",
    //   Field_Name: "startUp20",
    //   fieldType: FormFieldTypes.CHECKBOX,
    //   dataField: "startUp",
    //   fieldSetting: {
    //   },
    //   style: {
    //     containerCss:
    //       "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 inner-checkbox-left-space",
    //   },
    // },
    {
      id: "cpp",
      lable: "CPP (40)",
      Field_Name: "cPP40",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "cpp",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5  main-checkbox inner-checkbox-left-space",
      },
    },
    {
      id: "cpp100",
      lable: "CPP 200 (10)",
      Field_Name: "cPP200_10",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "cpp100",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "title4",
      lable: "",
      Field_Name: "",
      fieldType: FormFieldTypes.MAINFORMTITLE,
      dataField: "",
      fieldSetting: {
        formTitle: "Academic",
      },
      style: {
        containerCss:
          "col-10 mb-0 mt-0 text-left mx-auto sub-section-title ml-5",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    {
      id: "academic",
      lable: "Academic",
      Field_Name: "Academic",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "academic",
      fieldSetting: {
      },
      style: {
        containerCss:
          "col-xxl-10 col-xl-10 col-md-11 mb-3 mx-auto ml-5 main-checkbox inner-checkbox-left-space",
      },
    },
    {
      fieldType: FormFieldTypes.SEPARATOR,
      style: {
        containerCss: "  custom-separator col-10 mx-auto ml-5",
      },
    },
    // ------------------------------------------- Checkbox End -------------------------------------------

    // ========================================================================
    {
      id: "recipientsByGender",
      lable: "Recipients by Gender",
      Field_Name: "recipientsByGender",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByGender",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-4 main-checkbox",
      },
    },
    {
      id: "recipientsByJobTitle",
      lable: "Recipients by Job Title",
      Field_Name: "recipientsByJobTitle",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByJobTitle",
      fieldSetting: {
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-4 main-checkbox",
      },
    },
    {
      id: "recipientsByGenderSelect",
      lable: "",
      Field_Name: "recipientsByGenderSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByGenderSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-5",
      },
    },
    {
      id: "recipientsByJobTitleSelect",
      lable: "",
      Field_Name: "recipientsByJobTitleSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByJobTitleSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-5",
      },
    },

    // ========================================================================

    // ========================================================================
    {
      id: "recipientsByNationality",
      lable: "Recipients by Nationality",
      Field_Name: "recipientsByNationality",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByNationality",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByIndustry",
      lable: "Recipients by Industry",
      Field_Name: "recipientsByIndustry",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByIndustry",
      fieldSetting: {
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByNationalitySelect",
      lable: "",
      Field_Name: "recipientsByNationalitySelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByNationalitySelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },
    {
      id: "recipientsByIndustrySelect",
      lable: "",
      Field_Name: "recipientsByIndustrySelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByIndustrySelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },

    // ========================================================================

    // ========================================================================
    {
      id: "recipientsByTagging",
      lable: "Recipients by Tagging",
      Field_Name: "recipientsByTagging",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByTagging",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByStatus",
      lable: "Recipients by Status",
      Field_Name: "recipientsByStatus",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByStatus",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByTaggingSelect",
      lable: "",
      Field_Name: "recipientsByTaggingSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByTaggingSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },
    {
      id: "recipientsByStatusSelect",
      lable: "",
      Field_Name: "recipientsByStatusSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByStatusSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: [],
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },

    // ========================================================================

    // ========================================================================
    {
      id: "recipientsByRace",
      lable: "Recipients by Race",
      Field_Name: "recipientsByRace",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByRace",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByResidenceStatus",
      lable: "Recipients by Residence Status",
      Field_Name: "recipientsByResidenceStatus",
      fieldType: FormFieldTypes.CHECKBOX,
      dataField: "recipientsByResidenceStatus",
      fieldSetting: {
      },
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-2 mt-3 main-checkbox",
      },
    },
    {
      id: "recipientsByRaceSelect",
      lable: "",
      Field_Name: "recipientsByRaceSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByRaceSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },
    {
      id: "recipientsByResidenceStatusSelect",
      lable: "",
      Field_Name: "recipientsByResidenceStatusSelect",
      fieldType: FormFieldTypes.SELECT,
      dataField: "recipientsByResidenceStatusSelect",
      fieldSetting: {
        isMultiSelect: true,
        options: []
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-6 col-xl-6 col-md-6 mb-3",
      },
    },

    // ========================================================================

    // ========================================================================
    // ========================================================================


    {
      id: "includeEmail",
      lable: "Include Email",
      Field_Name: "includeEmail",
      fieldType: FormFieldTypes.LOWERCASEINPUT,
      dataField: "includeEmail",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-7 col-xl-7 col-md-8 mb-3 mt-4",
      },
    },
    {
      id: "excludeEmail",
      lable: "Exclude Email",
      Field_Name: "excludeEmail",
      fieldType: FormFieldTypes.LOWERCASEINPUT,
      dataField: "excludeEmail",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      // validation: [{ type: "require" }],
      style: {
        containerCss: "col-xxl-7 col-xl-7 col-md-8 mb-3",
      },
    },
  ],
};
