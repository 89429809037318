import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Buttons from '../../components/ui/button/Buttons';
import MembershipApplication from './component/membershipApplication/MembershipApplication';
import PersonalInformation from './component/personalInformation/PersonalInformation';
import ProfessionalCertification from './component/professionalCertification/ProfessionalCertification';
import ProfessionalCertificationDetails from './component/professionalCertificationDetails/ProfessionalCertificationDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptAESID } from '../../utils/Encryption';
import IndividualAccountInformationData from './component/accountInformation/data/IndividualAccountInformation.data';
import { EngagementTrackingNotesData } from './component/engagementTrackingNotes/data/EngagementTrackingAndNotes.data';
import MembershipApplicationData from './component/membershipApplication/data/MembershipApplication.data';
import PersonalInformationData from './component/personalInformation/data/PersonalInformation.data';
import ProfessionalCertificationDataGeneral from './component/professionalCertification/data/ProfessionalCertification.data';
import ProfessionalCertificationData from './component/professionalCertificationDetails/data/professionalCertificationDetails.data';
import IndividualAccountInformation from './component/accountInformation/IndividualAccountInformation';
import EngagementTrackingAndNotes from './component/engagementTrackingNotes/EngagementTrackingAndNotes';
import EdcuationInformation from './component/edcuationInformation/EdcuationInformation';
import EdcuationInformationData from './component/edcuationInformation/data/EdcuationInformation.data';
import EmploymentInformation from './component/employmentInformation/EmploymentInformation';
import { EmploymentInformationData } from './component/employmentInformation/data/EmploymentInformation.data';
import { setViewIndividualMemberDropDown } from './utils/setViewIndividualMemberDropDown';
import { v4 as uuidv4 } from 'uuid';
import FinancialRecord from './component/finacialRecord/FinancialRecord';
import { saveAs } from 'file-saver';
import OrdinaryPath3 from './component/forOrdinaryPath3/forOrdinaryPath3';
import { OrdinaryPathData } from './component/forOrdinaryPath3/Data/forOrdinaryPath3.Data';
import OrganisationDetailsData from './component/organisationDetails/data/OrganisationDetails.data';
import CommunicationData from './component/communication/data/Communication.data';
import ApplicationBackground from './component/applicationBackground/ApplicationBackground';
import ApplicationBackgroundData from './component/applicationBackground/data/ApplicationBackground.data';
import {
  onHandleNationalityDropdown,
  onHandleOrdinaryPath3,
  formatDateMMYYYY,
  onHandleGraduationDate,
} from "./utils/setEditFieldsUtils";
import { getAuthProps } from '../../lib/authenticationLibrary';
// Import API services
import {
  useLazyGetIndividuaMemberDetailByIdQuery,
  useLazyGetIndividuaSIISAccountInfoQuery,
  useUpdateIndividuaMemberByIdMutation,
  useAddEngagementTrackingDetailsMutation,
  useLazyGetIndividualEngagementTrackingListQuery,
  useInserLogForIndividualMembershipAndSubscriptionChangesMutation
} from '../../app/services/Individual/ViewIndividualMemberApi';
import {
  useUploadPDFMutation,
  useLazyGetPDFQuery,
  useLazyGetImageQuery,
  useStoreBase64ImageMutation
} from '../../app/services/Common/CommonApi';
import SwalAlert from '../../services/swalService/SwalService';
import ToastService from '../../services/toastService/ToastService';
import { useLazyGetAllDropdownsForIndividualMembersQuery } from '../../app/services/Individual/IndividualTaskListApi';
import DataLoader from '../../components/ui/dataLoader/DataLoader';
import OrganisationDetails from './component/organisationDetails/OrganisationDetails';
import Communication from './component/communication/Communication';
import { getCookie } from '../../utils/Cookies/CookieHandler';
import { useValidateAdminUserPasswordMutation } from '../../app/services/AdminServices/authApi';

const EditIndividualMembersForm = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const { error, confirmTextWithApi } = SwalAlert()
  const individualMemberId = decryptAESID(id);
  const [membershipTypeId, setMembershipTypeId] = useState(0);
  const [endorsement1FileName, setEndorsement1FileName] = useState(null);
  const [endorsement2FileName, setEndorsement2FileName] = useState(null);
  const [indivudalMembershipType, setIndivudalMembershipType] = useState(null);
  const [professionalCertificateData, setProfessionalCertificateData] = useState([]);
  const [edcuationInfoData, setEdcuationInfoData] = useState([]);
  const [editProfessionalCertificate, setEditProfessionalCertificate] = useState(false);
  const [editEdcuationInformation, setEditEdcuationInformation] = useState(false);
  const [editIdForEdcuationInformation, setEditIdForEdcuationInformation] = useState({
    id: "",
    individualEducationInformationId: 0,
    individualMemberId: 0,
  });
  const [editIdForProfessionalCertification, setEditIdForProfessionalCertification] = useState({
    id: "",
    individualProfessionalCertificationId: 0,
    individualMemberId: 0,
  });
  const [educationCertificatesFileName, setEducationCertificationFileName] = useState(null);
  const [professionalInformationSecurityCertificatesFileName, setProfessionalInformationSecurityCertificatesFileName] = useState(null);
  const [individualAccountInformationForm, setIndividualAccountInformationForm] = useState(IndividualAccountInformationData);
  const [engagementTrackingNotesForm, setEngagementTrackingNotesForm] = useState(EngagementTrackingNotesData);
  const [membershipApplicationForm, setMembershipApplicationForm] = useState(MembershipApplicationData);
  const [personalInformationForm, setPersonalInformationForm] = useState(PersonalInformationData);
  const [professionalCertificationDataGeneralForm, setProfessionalCertificationDataGeneralForm] = useState(ProfessionalCertificationDataGeneral);
  const [professionalCertificationForm, setProfessionalCertificationForm] = useState(ProfessionalCertificationData);
  const [edcuationInformationForm, setEdcuationInformationForm] = useState(EdcuationInformationData);
  const [employmentInformationForm, setemploymentInformationForm] = useState(EmploymentInformationData);
  const [ordinaryPathForm, setForOrdinaryPath3Form] = useState(OrdinaryPathData);
  const [organisationDetailsForm, setOrganisationDetailsForm] = useState(OrganisationDetailsData);
  const [communicationDetailsForm, setCommunicationDetailsForm] = useState(CommunicationData);
  const [applicationBackgroundForm, setApplicationBackgroundForm] = useState(ApplicationBackgroundData);
  const [isIndividualMember, setIsIndividualMember] = useState(true);
  const [individualTabList, setIndividualTabList] = useState();
  const [loginAdminId, setLoginAdminId] = useState("");
  const [engagementTrackingHistory, setEngagementTrackingHistory] = useState([]);
  const [applicationBackgroundFileName, setApplicationBackgroundFileName] = useState({
    suspendedFilePath: "",
    convictedFilePath: "",
    bodyTerminatedFileName: "",
    bankruptFilePath: "",
  });

  const individualAccountInformationRef = useRef()
  const engagementTrackingNotesRef = useRef()
  const membershipApplicationRef = useRef()
  const personalInformationRef = useRef()
  const professionalCertificationGeneralRef = useRef()
  const professionalCertificationRef = useRef()
  const edcuationInformationRef = useRef()
  const employmentInformationRef = useRef()
  const applicationBackgroundRef = useRef()
  const ordinaryPathFormRef = useRef()
  const organisationDetailsRef = useRef()
  const communicationDetailsRef = useRef()
  const engagementDetailsRef = useRef()

  useEffect(() => {
    let loginData = getAuthProps();
    setLoginAdminId(loginData.adminUserId);
    getEngagementTrackingAndNotes(individualMemberId);
  }, [individualMemberId])

  useEffect(() => {
    getAllDropDowns()
  }, []);

  const [getAllDropDowns, {
    data: dropdownResponse,
    isSuccess: dropdownIsSuccess,
    isLoading: dropdownIsLoading,
  }] = useLazyGetAllDropdownsForIndividualMembersQuery();

  useEffect(() => {
    if (!dropdownIsLoading && dropdownIsSuccess && dropdownResponse) {
      setViewIndividualMemberDropDown(dropdownResponse);
      getMemberDetails(individualMemberId);
      getIndividualSIISAccountInfo(individualMemberId);
    }
  }, [dropdownIsLoading, dropdownIsSuccess, dropdownResponse]);


  const [getIndividualSIISAccountInfo, {
    data: getIndividualSIISAccountInfoData,
    isSuccess: getIndividualSIISAccountInfoIsSuccess,
    isLoading: getIndividualSIISAccountInfoIsLoading,
  }] = useLazyGetIndividuaSIISAccountInfoQuery();

  useEffect(() => {
    if (!getIndividualSIISAccountInfoIsLoading && getIndividualSIISAccountInfoIsSuccess && getIndividualSIISAccountInfoData) {
      setAccountInformationFormInitials();
    }
  }, [getIndividualSIISAccountInfoIsLoading, getIndividualSIISAccountInfoIsSuccess, getIndividualSIISAccountInfoData])

  const [getMemberDetails, {
    data: getMemberDetailsData,
    isSuccess: getMemberDetailsIsSuccess,
    isLoading: getMemberDetailsIsLoading,
  }] = useLazyGetIndividuaMemberDetailByIdQuery();
  // Update individual memkber details API call
  const [updateIndividualMemberById, {
    data: updateIndividualMemberByIdData,
    isSuccess: updateIndividualMemberByIdIsSuccess,
    isLoading: updateIndividualMemberByIdIsLoading,
  }] = useUpdateIndividuaMemberByIdMutation();
  // Add engagement tracking and notes API call
  const [addEngagementTrackingAndNotes, {
    data: engagementTrackingNotesData,
    isSuccess: engagementTrackingNotesIsSuccess,
    isLoading: engagementTrackingNotesIsLoading,
  }] = useAddEngagementTrackingDetailsMutation();
  // Get engagement tracking and notes list API call
  const [getEngagementTrackingAndNotes, {
    data: engagementTrackingListData,
    isSuccess: engagementTrackingListIsSuccess,
    isLoading: engagementTrackingListIsLoading,
  }] = useLazyGetIndividualEngagementTrackingListQuery();
  // Upload image api call
  const [saveMainImage,
    { isSuccess: isSuccessImage,
      isLoading: isLoadingImage,
      data: profileImageUpload }
  ] = useStoreBase64ImageMutation();
  // Upload PDF api call
  const [uploadPDF,
    { isSuccess: isUploadSuccess,
      isLoading: isUploadLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();
  // Get PDF API call
  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }
  ] = useLazyGetPDFQuery();
  // Get image api call
  const [getImage,
    { isSuccess: isSuccessGetImage,
      isLoading: isLoadingGetImage,
      data: getImageData }
  ] = useLazyGetImageQuery();
  // Validate OTP API call
  const [validatePassword,
    { isLoading: loginLoading,
      isSuccess: loginSuccess,
      data: authData }] = useValidateAdminUserPasswordMutation();

  const [InserLogForIndividualMembershipAndSubscriptionChanges, {
    data: inserLogForIndividualMembershipAndSubscriptionChangesData,
    isSuccess: inserLogForIndividualMembershipAndSubscriptionChangesIsSuccess,
    isLoading: inserLogForIndividualMembershipAndSubscriptionChangesIsLoading,
  }] = useInserLogForIndividualMembershipAndSubscriptionChangesMutation();


  useEffect(() => {
    if (!loginLoading && loginSuccess && authData) {
      if (authData.isAuthenticated === false) {
        // ToastService.error('Incorrect Password');
      } else if (authData.isAuthenticated === true) {
      }
    }
  }, [loginLoading, loginSuccess, authData]);


  useEffect(() => {
    if (!getMemberDetailsIsLoading && getMemberDetailsIsSuccess && getMemberDetailsData) {
      setProfessionalCertificationFormInitials();
      setEdcuationInformationFormInitials();
      setIndivudalMembershipType(getMemberDetailsData.membershipTypeId);
      setEducationCertificationFileName(getMemberDetailsData?.educationCertificatesFileName);
      setEndorsement1FileName(getMemberDetailsData.endorsementSignatureForNominator1);
      setEndorsement2FileName(getMemberDetailsData.endorsementSignatureForNominator2);
      setProfessionalInformationSecurityCertificatesFileName(getMemberDetailsData?.ProfessionalInformationSecurityCertificatesFileName);
      setMembershipApplicationFormInitials();
      setPersonalInformationFormInitials();
      setProfessionalCertificationDataGeneralFormInitials();
      setEmploymentInformationFormInitials();
      setApplicationBackgroundFormInitials();
      setForOrdinaryPath3FormInitials();
      setForOrganisationDetailsFormInitials();
      setForCommunicationDetailsFormInitials();
      if (getMemberDetailsData.membershipTypeId !== 4) {
        let tabs = accordionData().filter(obj => obj.id !== "forOrdinaryPath3");
        setIndividualTabList(tabs);
      }
      else {
        setIndividualTabList(accordionData());
      }
    }
  }, [getMemberDetailsIsLoading, getMemberDetailsIsSuccess, getMemberDetailsData])


  //Image upload response
  useEffect(() => {
    if (!isLoadingImage && isSuccessImage && profileImageUpload) {
      ToastService.success("Image uploaded successfully !")
    }
  }, [isLoadingImage, isSuccessImage, profileImageUpload,]);
  //Upload PDF response
  useEffect(() => {
    if (!isUploadSuccess && isUploadLoading && fileUploadData) {
      ToastService.success("PDF uploaded successfully !")
    }
  }, [isUploadSuccess, isUploadLoading, fileUploadData,]);
  // Update individual member details response
  useEffect(() => {
    if (!updateIndividualMemberByIdIsLoading && updateIndividualMemberByIdIsSuccess && updateIndividualMemberByIdData) {
      ToastService.success("Update Successfully")
      getMemberDetails(individualMemberId);
    }
  }, [updateIndividualMemberByIdIsLoading, updateIndividualMemberByIdIsSuccess, updateIndividualMemberByIdData])
  // Get engagement tracking list response
  useEffect(() => {
    if (!engagementTrackingListIsLoading && engagementTrackingListIsSuccess && engagementTrackingListData) {
      setEngagementTrackingHistory(engagementTrackingListData);
    }
  }, [engagementTrackingListIsLoading, engagementTrackingListIsSuccess, engagementTrackingListData])
  // Add engagement tracking and notes response
  useEffect(() => {
    if (!engagementTrackingNotesIsLoading && engagementTrackingNotesIsSuccess && engagementTrackingNotesData) {
      ToastService.success("Engagement notes added successfully");
      getEngagementTrackingAndNotes(individualMemberId);
      resetForm();
    }
  }, [engagementTrackingNotesIsLoading, engagementTrackingNotesIsSuccess, engagementTrackingNotesData])


  const setMembershipApplicationFormInitials = () => {
    let temp = { ...membershipApplicationForm }
    temp.initialState = {}
    temp.initialState.membershipType = getMemberDetailsData.membershipTypeId?.value ? getMemberDetailsData.membershipTypeId.value : getMemberDetailsData.membershipTypeId;
    temp.initialState.membershipTenure = getMemberDetailsData.membershipTenureId?.value ? getMemberDetailsData.membershipTenureId.value : getMemberDetailsData.membershipTenureId;
    temp.initialState.preferredStartDate = getMemberDetailsData.startDate;
    temp.initialState.membershipStartDate = getMemberDetailsData.subscriptionJoinDate;
    temp.initialState.membershipEndDate = getMemberDetailsData.subscriptionEndDate;
    temp.initialState.billingCategory = getMemberDetailsData.individualMemberBillingCategoryId;

    setMembershipApplicationForm({ ...temp });
    setMembershipTypeId(getMemberDetailsData.membershipTypeId);
    let tempObj = {
      label: getMemberDetailsData.membershipTypeText,
      value: getMemberDetailsData.membershipTypeId
    }
    let updatedForm = onHandleOrdinaryPath3(tempObj, ordinaryPathForm);
    setForOrdinaryPath3Form(updatedForm);
  }
  const setPersonalInformationFormInitials = () => {
    let temp = { ...personalInformationForm }
    temp.initialState.salutationsID = getMemberDetailsData.salutationId;
    temp.initialState.firstNameasInPassport = getMemberDetailsData.firstName;
    temp.initialState.lastNameasInPassport = getMemberDetailsData.lastName;
    temp.initialState.contactNumber = getMemberDetailsData.contactNo;
    temp.initialState.howWouldYouLikeUsToAddressYou = getMemberDetailsData.likeUsToAddressYou;
    temp.initialState.isGenderMale = getMemberDetailsData.isMale ? 'male' : 'female';
    temp.initialState.nationality = getMemberDetailsData.nationalityTypeId.value ? getMemberDetailsData.nationalityTypeId.value : getMemberDetailsData.nationalityTypeId;
    temp.initialState.countryOfResidence = getMemberDetailsData.countryOfResidenceId.value ? getMemberDetailsData.countryOfResidenceId.value : getMemberDetailsData.countryOfResidenceId;
    temp.initialState.residentStatusId = getMemberDetailsData.residentStatusId.value ? getMemberDetailsData.residentStatusId.value : getMemberDetailsData.residentStatusId;
    temp.initialState.raceId = getMemberDetailsData.raceId.value ? getMemberDetailsData.raceId.value : getMemberDetailsData.raceId;
    temp.initialState.businessEmail = getMemberDetailsData.businessEmail;
    temp.initialState.alternateEmail = getMemberDetailsData.personalEmail;

    // Split the linkedin link and get user name
    var linkedinProfileLink = getMemberDetailsData?.linkedinProfile || null
    var resultuntArr = getMemberDetailsData?.linkedinProfile ? linkedinProfileLink.split("/") : null;

    temp.initialState.linkedInProfile = "https://www.linkedin.com/in/";
    temp.initialState.userName = getMemberDetailsData?.linkedinProfile ? (resultuntArr.slice(-1).pop()).toUpperCase() : "" || null;
    // temp.initialState.linkedInProfile = getMemberDetailsData.linkedinProfile;

    temp.initialState.graduationDate = getMemberDetailsData.graduationDate;
    setPersonalInformationForm(temp);
    let data = {
      value: getMemberDetailsData.nationalityTypeId
    }
    let updatedForm = onHandleNationalityDropdown(data, personalInformationForm);
    setPersonalInformationForm(updatedForm);

    if (getMemberDetailsData.studentId !== null) {
      let graduationDateData = {
        value: getMemberDetailsData.graduationDate
      };
      let updatedFormWithGraduation = onHandleGraduationDate(graduationDateData, updatedForm);
      setPersonalInformationForm(updatedFormWithGraduation);
    } else {

      const graduationDateIndex = updatedForm.formFields.findIndex(field => field.id === "graduationDate");
      if (graduationDateIndex !== -1) {
        updatedForm.formFields[graduationDateIndex].style.containerCss = "col-md-6 mb-3 d-none";
        setPersonalInformationForm(updatedForm);
      }
    }
  }

  const setProfessionalCertificationDataGeneralFormInitials = () => {
    let temp = { ...professionalCertificationDataGeneralForm }
    temp.initialState.yearsofProfessionalPracticeIT = formatDateMMYYYY(getMemberDetailsData.yearsOfProfessionalPracticeInIT);
    temp.initialState.yearsOfProfessionalPracticeIS = formatDateMMYYYY(getMemberDetailsData.yearsOfProfessionalPracticeInIS);
    temp.initialState.professionCodeId = getMemberDetailsData.professionCodeId.value ? getMemberDetailsData.professionCodeId.value : getMemberDetailsData.professionCodeId;
    setProfessionalCertificationDataGeneralForm(temp);
  }
  const setProfessionalCertificationFormInitials = () => {
    let tempArr = getMemberDetailsData.individualProfessionalCertification.map((item) => { return ({ ...item, id: uuidv4() }) })
    let temp = { ...professionalCertificationForm };
    temp.formFields = temp.formFields.map(field => {
      if (field.id === "professionalInfoTable") {
        return {
          ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
        };
      }
      return field;
    });
    setProfessionalCertificationForm({ ...temp });
    setProfessionalCertificateData(tempArr)
  }
  const setEdcuationInformationFormInitials = () => {
    let tempArr = getMemberDetailsData.individualEducationInformation.map((item) => { return ({ ...item, id: uuidv4() }) })
    let temp = { ...edcuationInformationForm };
    temp.formFields = temp.formFields.map(field => {
      if (field.id === "edcuationalInfoTable") {
        return {
          ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
        };
      }
      return field;
    });
    setEdcuationInformationForm({ ...temp });
    setEdcuationInfoData(tempArr);
  }
  const setEmploymentInformationFormInitials = () => {
    let temp = { ...employmentInformationForm }
    temp.initialState.organisationName = getMemberDetailsData.organisationName;
    temp.initialState.officeNo = getMemberDetailsData.officeNo;
    temp.initialState.jobFunctionId = getMemberDetailsData.jobFunctionId.value ? getMemberDetailsData.jobFunctionId.value : getMemberDetailsData.jobFunctionId;
    temp.initialState.jobLevelId = getMemberDetailsData.jobLevelId.value ? getMemberDetailsData.jobLevelId.value : getMemberDetailsData.jobLevelId;
    temp.initialState.industryCodeId = getMemberDetailsData.industryCodeId.value ? getMemberDetailsData.industryCodeId.value : getMemberDetailsData.industryCodeId;
    temp.initialState.resumeFileName = getMemberDetailsData.resumeFileName;

    setemploymentInformationForm(temp);
  }
  const setApplicationBackgroundFormInitials = () => {
    let temp = { ...applicationBackgroundForm }
    temp.initialState.isconvicted = getMemberDetailsData.isconvicted ? 'yes' : 'no';
    temp.initialState.convictedFilePath = getMemberDetailsData.convictedFilePath;
    temp.initialState.isSuspended = getMemberDetailsData.isSuspended ? 'yes' : 'no';
    temp.initialState.suspendedFilePath = getMemberDetailsData.suspendedFilePath;
    temp.initialState.isBankrupt = getMemberDetailsData.isBankrupt ? 'yes' : 'no';
    temp.initialState.bankruptFilePath = getMemberDetailsData.bankruptFilePath;
    temp.initialState.isBodyTerminated = getMemberDetailsData.isBodyTerminated ? 'yes' : 'no';
    temp.initialState.bodyTerminatedFileName = getMemberDetailsData.bodyTerminatedFileName;
    setApplicationBackgroundForm(temp);
  }
  const setForOrdinaryPath3FormInitials = () => {
    let temp = { ...ordinaryPathForm }
    temp.initialState.nameofNominator1 = getMemberDetailsData.nameOfNominator1;
    temp.initialState.membershipNumberForNominator1 = getMemberDetailsData.membershipNumberForNominator1;
    temp.initialState.excoPositionIdForNominator1 = getMemberDetailsData.excoPositionIdForNominator1;
    temp.initialState.excoTermIdForNominator1 = getMemberDetailsData.excoTermIdForNominator1;
    temp.initialState.uploadEndorsement = getMemberDetailsData.endorsementSignatureForNominator1;
    temp.initialState.nameofNominator2 = getMemberDetailsData.nameOfNominator2;
    temp.initialState.membershipNumberForNominator2 = getMemberDetailsData.membershipNumberForNominator2;
    temp.initialState.excoPositionIdForNominator2 = getMemberDetailsData.excoPositionIdForNominator2;
    temp.initialState.excoTermIdForNominator2 = getMemberDetailsData.excoTermIdForNominator2;
    temp.initialState.uploadEndorsement2 = getMemberDetailsData.endorsementSignatureForNominator2;
    setForOrdinaryPath3Form(temp);
  }
  const setForOrganisationDetailsFormInitials = () => {
    let temp = { ...organisationDetailsForm }
    temp.initialState.nameOfOrganisationAsInAcra = getMemberDetailsData.corporateOrganisationName;
    temp.initialState.brnUenNo = getMemberDetailsData.uenNo;
    temp.initialState.organisationAddressLine1 = getMemberDetailsData.addressLine1;
    temp.initialState.organisationAddressLine2 = getMemberDetailsData.addressLine2;
    temp.initialState.organisationAddressLine3 = getMemberDetailsData.addressLine3;
    temp.initialState.postalCode = getMemberDetailsData.postalCode;
    temp.initialState.corporateIndustryCodeId = getMemberDetailsData.corporateIndustryCodeId;
    setOrganisationDetailsForm(temp);
  }

  const safeSplit = (value, from = ',') => {
    return (typeof value === 'string' && value.trim() !== '')
      ? value.split(from).map(item => Number(item.trim()))
      : [];
  };
  const setForCommunicationDetailsFormInitials = () => {
    let temp = { ...communicationDetailsForm }
    temp.initialState.bulletCheckbox = getMemberDetailsData.isBulletin;
    temp.initialState.newsLetterCheckBox = getMemberDetailsData.isNewsLetter;
    temp.initialState.EDMCheckbox = getMemberDetailsData.isEDM;
    temp.initialState.tagId = safeSplit(getMemberDetailsData.tagId);
    setCommunicationDetailsForm(temp);
  }
  const setAccountInformationFormInitials = () => {
    let temp = { ...individualAccountInformationForm }
    temp.initialState.accountStatus = getIndividualSIISAccountInfoData?.accountStatus;
    temp.initialState.reasonForLeaving = getIndividualSIISAccountInfoData?.deletionReasonId;
    temp.initialState.joinedDate = getIndividualSIISAccountInfoData?.corporateJoinedDate;
    temp.initialState.updatedDate = getIndividualSIISAccountInfoData?.updatedDate;
    temp.initialState.updatedBy = getIndividualSIISAccountInfoData?.updatedBy;
    temp.initialState.isQRScan = getIndividualSIISAccountInfoData?.isQRScan;
    temp.initialState.isBlockDataPurging = getIndividualSIISAccountInfoData?.isBlockDataPurging;
    setIndividualAccountInformationForm(temp);
  }
  const onBackButton = () => {
    navigate("/viewIndividualMembers")
  }

  const handleEdit = (dataRow, name) => {
    if (name === "ProfessionalCertification") {
      let temp = { ...professionalCertificationForm };
      temp.initialState.professionalCertifications = dataRow.professionalCertificationName
      temp.initialState.year = dataRow.professionalCertificationYearsId
      temp.initialState.professionalInformationFile = dataRow.professionalInformationSecurityCertificatesFileName
      setProfessionalCertificationForm({ ...temp });
      setEditProfessionalCertificate(true)
      let tempObj = {
        id: dataRow.id,
        individualProfessionalCertificationId: dataRow.individualProfessionalCertificationId,
        individualMemberId: dataRow.individualMemberId,
      }
      setEditIdForProfessionalCertification({ ...tempObj })

    }
    else if (name === "EdcuationInformation") {
      let temp = { ...edcuationInformationForm }
      temp.initialState.educationInstitutionName = dataRow.educationInstitutionName
      temp.initialState.yearOfComplition = dataRow.yearOfCompletionId
      temp.initialState.highestEducationalQualification = dataRow?.highestEducationalQualificationId
        || dataRow.educationalQualificationId
      temp.initialState.edcuationCertificate = dataRow.educationCertificatesFileName
      setEditEdcuationInformation(true)
      setEdcuationInformationForm({ ...temp });
      let objTmp =
      {
        id: dataRow.id,
        individualEducationInformationId: dataRow.individualEducationInformationId,
        individualMemberId: dataRow.individualMemberId,
      }
      setEditIdForEdcuationInformation({ ...objTmp })
    }
  }
  const handeDelete = (dataRow, name) => {
    if (name === "ProfessionalCertification") {
      let temp = { ...professionalCertificationForm };
      let tempArr = professionalCertificateData.filter(obj => obj.individualProfessionalCertificationId !== dataRow.individualProfessionalCertificationId)
      let tempFormFields = temp.formFields.map(field => {
        if (field.id === "professionalInfoTable") {
          return {
            ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
          };
        }
        return field;
      });
      temp.formFields = tempFormFields;
      temp.initialState = {
        professionalCertifications: '',
        year: '',
        professionalInformationFile: '',
      }
      setProfessionalCertificationForm({ ...temp });
      setProfessionalCertificateData(tempArr)
    }
    else if (name === "EdcuationInformation") {
      let temp = { ...edcuationInformationForm }
      let tempArr = edcuationInfoData.filter(obj => obj.individualEducationInformationId !== dataRow.individualEducationInformationId)
      let tempFormFields = temp.formFields.map(field => {
        if (field.id === "edcuationalInfoTable") {
          return {
            ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
          };
        }
        return field;
      });
      temp.formFields = tempFormFields;
      temp.initialState = {
        educationInstitution: '',
        yearOfComplition: '',
        highestEducationalQualification: '',
        educationCertificates: '',
      }
      setEdcuationInformationForm({ ...temp });
      setEdcuationInfoData(tempArr)
    }

  }
  const handleAdd = (dataRow, name) => {
    if (name === "ProfessionalCertification") {
      let temp = { ...professionalCertificationForm };
      let tempArr = [...professionalCertificateData]
      const professionalCertificationData = professionalCertificationRef.current.getFormData();
      let obj = {
        id: uuidv4(),
        individualProfessionalCertificationId: professionalCertificationData.individualProfessionalCertificationId,
        individualMemberId: professionalCertificationData.individualMemberId,
        professionalCertificationName: professionalCertificationData.professionalCertifications,
        professionalCertificationYearsId: professionalCertificationData.year.value ? professionalCertificationData.year.value : professionalCertificationData.year,
        professionalInformationSecurityCertificatesFileName: professionalCertificationData.professionalInformationFile?.name ?
          professionalCertificationData.professionalInformationFile?.name : professionalCertificationData?.professionalInformationFile?.fileName ? professionalCertificationData.professionalInformationFile?.fileName : professionalCertificationData.professionalInformationFile,

        yearName: professionalCertificationData.year?.label ? professionalCertificationData.year.label : professionalCertificationData.year,
      }
      tempArr.push(obj)
      let tempFormFields = temp.formFields.map(field => {
        if (field.id === "professionalInfoTable") {
          return {
            ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
          };
        }
        return field;
      });
      temp.formFields = tempFormFields;
      temp.initialState = {
        professionalCertifications: '',
        year: '',
        professionalInformationFile: '',
      }
      setProfessionalCertificationForm({ ...temp });
      setProfessionalCertificateData(tempArr)
    }
    else if (name === "EdcuationInformation") {
      let temp = { ...edcuationInformationForm }
      let tempArr = [...edcuationInfoData]
      const edcuationInformationData = edcuationInformationRef.current.getFormData();

      let obj = {
        id: uuidv4(),
        individualEducationInformationId: edcuationInformationData.individualEducationInformationId,
        educationInstitutionName: edcuationInformationData.educationInstitutionName,
        yearOfCompletionId: edcuationInformationData.yearOfComplition?.value ? edcuationInformationData.yearOfComplition.value : edcuationInformationData.yearOfComplition,
        highestEducationalQualificationId: edcuationInformationData.highestEducationalQualification?.value ? edcuationInformationData.highestEducationalQualification.value : edcuationInformationData.highestEducationalQualification,

        educationCertificatesFileName: edcuationInformationData.edcuationCertificate?.name ? edcuationInformationData.edcuationCertificate?.name :
          edcuationInformationData?.edcuationCertificate?.fileName ? edcuationInformationData.edcuationCertificate?.fileName : edcuationInformationData.edcuationCertificate,

        individualMemberId: edcuationInformationData.individualMemberId,
        yearName: edcuationInformationData.yearOfComplition?.label ? edcuationInformationData.yearOfComplition.label : edcuationInformationData.yearOfComplition,
        educationalQualificationName: edcuationInformationData.highestEducationalQualification?.label ? edcuationInformationData.highestEducationalQualification.label : edcuationInformationData.highestEducationalQualification,
        educationInstitutionName: edcuationInformationData.educationInstitutionName,

      }
      tempArr.push(obj)
      let tempFormFields = temp.formFields.map(field => {
        if (field.id === "edcuationalInfoTable") {
          return {
            ...field, fieldSetting: { ...field.fieldSetting, dataSource: tempArr }
          };
        }
        return field;
      });
      temp.initialState = {
        educationInstitution: '',
        yearOfComplition: '',
        highestEducationalQualification: '',
        educationCertificate: '',
      }
      temp.formFields = tempFormFields;
      setEdcuationInformationForm({ ...temp });
      setEdcuationInfoData(tempArr)
    }
  }
  const getLabel = (id, value) => {
    return edcuationInformationForm?.formFields?.find(field => field.id === id)?.fieldSetting?.options?.find(opt => opt.value === value)?.label || null
  }
  const handleUpdate = (dataRow, name) => {
    if (name === "ProfessionalCertification") {
      const existingItemIndex = professionalCertificateData.findIndex(item => item.id === editIdForProfessionalCertification.id)
      if (existingItemIndex !== -1) {
        let temp = { ...professionalCertificationForm };
        const updatedList = [...professionalCertificateData];
        const professionalCertificationData = professionalCertificationRef.current.getFormData();

        let obj = {
          id: editIdForProfessionalCertification.id,
          individualProfessionalCertificationId: editIdForProfessionalCertification.individualProfessionalCertificationId,
          individualMemberId: editIdForProfessionalCertification.individualMemberId,
          professionalCertificationName: professionalCertificationData.professionalCertifications,
          professionalCertificationYearsId: professionalCertificationData.year.value ? professionalCertificationData.year.value : professionalCertificationData.year,
          professionalInformationSecurityCertificatesFileName: professionalCertificationData.professionalInformationFile?.name ? professionalCertificationData.professionalInformationFile?.name : professionalCertificationData.professionalInformationFile,
          yearName: professionalCertificationData.year?.label ? professionalCertificationData.year.label : professionalCertificationForm?.formFields?.find(field => field.id === "year")?.fieldSetting?.options?.find(opt => opt.value === professionalCertificationData.year)?.label || null,
        }
        updatedList[existingItemIndex] = obj;
        let tempFormFields = temp.formFields.map(field => {
          if (field.id === "professionalInfoTable") {
            return {
              ...field, fieldSetting: { ...field.fieldSetting, dataSource: updatedList }
            };
          }
          return field;
        });
        temp.formFields = tempFormFields;
        temp.initialState = {
          professionalCertifications: '',
          year: '',
          professionalInformationFile: '',
        }
        setProfessionalCertificationForm({ ...temp });
        setProfessionalCertificateData(updatedList)
      }
      setEditProfessionalCertificate(false);
      setEditIdForProfessionalCertification(null);
    }
    else if (name === "EdcuationInformation") {
      const existingItemIndex = edcuationInfoData.findIndex(item => item.id === editIdForEdcuationInformation.id)
      if (existingItemIndex !== -1) {
        let temp = { ...edcuationInformationForm };
        const updatedList = [...edcuationInfoData];
        const edcuationInformationData = edcuationInformationRef.current.getFormData();
        let obj = {
          id: editIdForEdcuationInformation.id,
          individualEducationInformationId: editIdForEdcuationInformation.individualEducationInformationId,
          educationInstitutionName: edcuationInformationData.educationInstitutionName,
          yearOfCompletionId: edcuationInformationData.yearOfComplition?.value ? edcuationInformationData.yearOfComplition.value : edcuationInformationData.yearOfComplition,
          highestEducationalQualificationId: edcuationInformationData.highestEducationalQualification?.value ? edcuationInformationData.highestEducationalQualification.value : edcuationInformationData.highestEducationalQualification,
          educationCertificatesFileName: edcuationInformationData.edcuationCertificate?.name ? edcuationInformationData.edcuationCertificate?.name : edcuationInformationData.edcuationCertificate,
          individualMemberId: editIdForEdcuationInformation.individualMemberId,

          yearName: edcuationInformationData.yearOfComplition?.label ? edcuationInformationData.yearOfComplition.label : getLabel("yearOfComplition", edcuationInformationData.yearOfComplition),
          educationalQualificationName: edcuationInformationData.highestEducationalQualification?.label ? edcuationInformationData.highestEducationalQualification.label : getLabel("highestEducationalQualification", edcuationInformationData.highestEducationalQualification),
          educationInstitutionName: edcuationInformationData.educationInstitutionName?.label ? edcuationInformationData.educationInstitutionName?.label : edcuationInformationData.educationInstitutionName
        }
        updatedList[existingItemIndex] = obj;
        let tempFormFields = temp.formFields.map(field => {
          if (field.id === "edcuationalInfoTable") {
            return {
              ...field, fieldSetting: { ...field.fieldSetting, dataSource: updatedList }
            };
          }
          return field;
        });
        temp.initialState = {
          educationInstitution: '',
          yearOfComplition: '',
          highestEducationalQualification: '',
          educationCertificates: '',
        }
        temp.formFields = tempFormFields;
        setEdcuationInformationForm({ ...temp });
        setEdcuationInfoData(updatedList)
      }
      setEditIdForEdcuationInformation(null);
      setEditEdcuationInformation(false);
    }
  }
  // Download uploaded files method
  // const handleDownload = async (dataRow, name) => {
  //   let data = { ...applicationBackgroundForm };
  //   let data1 = { ...employmentInformationForm };
  //   const fileMappings = {
  //     "resumeFileName": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data1.initialState.resumeFileName
  //     },
  //     "EdcuationInformation": {
  //       type: "EdcuationCertificate",
  //       fileName: dataRow?.educationCertificatesFileName || educationCertificatesFileName
  //     },
  //     "ProfessionalCertification": {
  //       type: "ProfessionalCertification",
  //       fileName: dataRow?.professionalInformationSecurityCertificatesFileName || professionalInformationSecurityCertificatesFileName
  //     },
  //     "convictedFilePath": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.convictedFilePath
  //     },
  //     "suspendedFilePath": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.suspendedFilePath
  //     },
  //     "bankruptFilePath": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.bankruptFilePath
  //     },
  //     "bodyTerminatedFileName": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.bodyTerminatedFileName
  //     },
  //     "uploadEndorsement": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || dataRow?.contractInputFile
  //     },
  //     "uploadEndorsement2": {
  //       type: "IndividualSignUp",
  //       fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || dataRow?.contractInputFile
  //     }
  //   };
  //   const request = fileMappings[name];
  //   let checkTypeOf = typeof dataRow.contractInputFile
  //   let getExtension = request.fileName.split('.').pop();
  //   if (!request) return;
  //   if (checkTypeOf === "string" && getExtension === "pdf" ?
  //     data.initialState.convictedFilePath
  //     || data.initialState.suspendedFilePath
  //     || data.initialState.bankruptFilePath
  //     || data.initialState.bodyTerminatedFileName
  //     || endorsement1FileName
  //     || endorsement2FileName
  //     :
  //     "name" in dataRow.contractInputFile
  //   ) {
  //     const response = await getPDF(request);
  //     if (response?.data?.fileData && response?.data?.fileName) {
  //       saveAs(response.data.fileData, response.data.fileName);
  //     }
  //   }
  //   else if ("fileName" in dataRow.contractInputFile) {
  //     const response = await getImage(request);
  //     if (response?.data?.fileData && response?.data?.fileName) {
  //       saveAs(response.data.fileData, response.data.fileName);
  //     }
  //   }
  //   else {
  //     error("File is not exist in the server");
  //   }
  // };

  const handleDownload = async (dataRow, name) => {
    let data = { ...applicationBackgroundForm };
    let data1 = { ...employmentInformationForm };
    const fileMappings = {
      "resumeFileName": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data1.initialState.resumeFileName
      },
      "EdcuationInformation": {
        type: "EdcuationCertificate",
        fileName: dataRow?.educationCertificatesFileName || educationCertificatesFileName
      },
      "ProfessionalCertification": {
        type: "ProfessionalCertification",
        fileName: dataRow?.professionalInformationSecurityCertificatesFileName || professionalInformationSecurityCertificatesFileName
      },
      "convictedFilePath": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.convictedFilePath
      },
      "suspendedFilePath": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.suspendedFilePath
      },
      "bankruptFilePath": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.bankruptFilePath
      },
      "bodyTerminatedFileName": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || data.initialState.bodyTerminatedFileName
      },
      "uploadEndorsement": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || dataRow?.contractInputFile
      },
      "uploadEndorsement2": {
        type: "IndividualSignUp",
        fileName: dataRow?.contractInputFile?.name ? dataRow?.contractInputFile?.name : dataRow?.contractInputFile?.fileName || dataRow?.contractInputFile
      }
    };
    // const request = fileMappings[name];
    // let checkTypeOf = typeof dataRow.contractInputFile
    // let getExtension = request.fileName.split('.').pop();
    // if (!request) return;
    // if (checkTypeOf === "string" && getExtension === "pdf" ?
    //   data.initialState.convictedFilePath
    //   || data.initialState.suspendedFilePath
    //   || data.initialState.bankruptFilePath
    //   || data.initialState.bodyTerminatedFileName
    //   || endorsement1FileName
    //   || endorsement2FileName
    //   :
    //   "fileName" in dataRow.contractInputFile
    // ) {
    //   const response = await getPDF(request);
    //   if (response?.data?.fileData && response?.data?.fileName) {
    //     saveAs(response.data.fileData, response.data.fileName);
    //   }
    // }
    // else if ("fileName" in dataRow.contractInputFile) {
    //   const response = await getImage(request);
    //   if (response?.data?.fileData && response?.data?.fileName) {
    //     saveAs(response.data.fileData, response.data.fileName);
    //   }
    // }
    // else {
    //   error("File is not exist in the server");
    // }
    const request = fileMappings[name];
    if (!request) return;

    let checkTypeOf = typeof dataRow.contractInputFile;
    let getExtension = request.fileName.split('.').pop().toLowerCase();

    if (
      (checkTypeOf === "string" || checkTypeOf === "object") && getExtension === "pdf" &&
      (
        data.initialState.convictedFilePath ||
        data.initialState.suspendedFilePath ||
        data.initialState.bankruptFilePath ||
        data.initialState.bodyTerminatedFileName ||
        endorsement1FileName ||
        endorsement2FileName
      )
    ) {

      const response = await getPDF(request);
      if (response?.data?.fileData && response?.data?.fileName) {
        saveAs(response.data.fileData, response.data.fileName);
      }
    } else if (checkTypeOf === "string" && getExtension === "jpg") {
      const response = await getImage(request);
      if (response?.data?.fileData && response?.originalArgs?.fileName) {
        saveAs(response.data.fileData, response.originalArgs.fileName);
      }
    } else if (checkTypeOf === "object" && getExtension === "jpg") {

      const response = await getImage(request);
      if (response?.data?.fileData && response?.originalArgs?.fileName) {
        saveAs(response.data.fileData, response.originalArgs.fileName);
      }
    } else {
      error("File does not exist on the server");
    }

  };
  // Handle file upload method
  const handleOnChangeFileUpload = (dataField, data) => {
    let pathName = "";

    switch (dataField) {
      case "professionalInformationFile":
        pathName = "ProfessionalInformationFile";
        break;
      case "edcuationCertificate":
        pathName = "EdcuationCertificate";
        break;
      case "resumeFileName":
      case "uploadEndorsement":
      case "uploadEndorsement2":
      case "suspendedFilePath":
      case "convictedFilePath":
      case "bodyTerminatedFileName":
      case "bankruptFilePath":
        pathName = "IndividualSignUp";
        break;
      default:
        console.error("Unknown dataField: ", dataField);
        return; // Exit the function if dataField is unknown
    }
    if ("name" in data) {
      handlePdfFileUpload(data, pathName);
    }
    else {
      handleImageUpload(data);
    }
  };
  // Upload pdf method
  const handlePdfFileUpload = (data, pathName) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", pathName);
    uploadPDF(formData);
  }
  // Upload images method
  const handleImageUpload = async (data) => {
    const { base64Data, fileName } = data
    let saveFileRequest = {
      fileName: fileName,
      fileStr: base64Data,
      storagePath: "IndividualSignUpImage",
    }
    const response = await saveMainImage(saveFileRequest);
    // let resp = response;
  }
  // Handle nationality dropdown change
  const handleOnNationalityDropdownChange = (data, dataField) => {
    if (dataField === "nationality") {
      let updatedForm = onHandleNationalityDropdown(data, personalInformationForm);
      setPersonalInformationForm(updatedForm);
    }
  };

  // const safeJoin = (value) => (value && Array.isArray(value) ? value.join(', ') : '');
  // Save/Update individual data
  const saveButton = () => {
    const accountInformationData = individualAccountInformationRef?.current?.getFormData();
    const membershipApplicationData = membershipApplicationRef?.current?.getFormData();
    // const engagementTrackingNotesData = engagementTrackingNotesRef?.current?.getFormData();
    const personalInformationData = personalInformationRef?.current?.getFormData();
    const professionalCertificationGeneralData = professionalCertificationGeneralRef?.current?.getFormData();
    const employmentInformationData = employmentInformationRef?.current?.getFormData();
    const applicationBackgroundData = applicationBackgroundRef?.current?.getFormData();
    const ordinaryPathData = ordinaryPathFormRef?.current?.getFormData();
    const communicationData = communicationDetailsRef?.current?.getFormData();

    if (membershipApplicationData
      && personalInformationData
      && professionalCertificationGeneralData
      && employmentInformationData
      && applicationBackgroundData
    ) {

      let selectedMembershipType = membershipApplicationData?.membershipType?.value ? membershipApplicationData?.membershipType?.value : membershipApplicationData?.membershipType;
      let selectedEndDate = membershipApplicationData?.membershipEndDate;
      const userData = getCookie('AdminAuthUser');
      if (userData) {
        if (selectedMembershipType !== getMemberDetailsData?.membershipTypeId || selectedEndDate !== getMemberDetailsData?.subscriptionEndDate) {
          let updateDataLogRequest = {
            IndividualMemberId: individualMemberId,
            InitialMembershipType: getMemberDetailsData?.membershipTypeId,
            UpdatedMembershipType: selectedMembershipType,
            InitialSubscriptionEndDate: getMemberDetailsData?.subscriptionEndDate,
            UpdatedSubscriptionEndDate: selectedEndDate,
            UpdatedByAdminId: userData.adminUserId,
            UpdatedByAdminName: userData.userName,
          }
          InserLogForIndividualMembershipAndSubscriptionChanges(updateDataLogRequest)
        }
      }
      else {
        navigate("/login")
      }

      const request = {
        individualMemberId: individualMemberId,
        IsQRScan: accountInformationData.isQRScan,
        IsBlockDataPurging: accountInformationData.isBlockDataPurging,
        membershipTypeId: membershipApplicationData?.membershipType?.value ? membershipApplicationData?.membershipType?.value : membershipApplicationData?.membershipType,
        membershipTenureId: membershipApplicationData?.membershipTenure?.value ? membershipApplicationData?.membershipTenure?.value : membershipApplicationData?.membershipTenure,
        startDate: membershipApplicationData?.preferredStartDate,
        SubscriptionJoinDate: membershipApplicationData?.membershipStartDate,
        SubscriptionEndDate: membershipApplicationData?.membershipEndDate,

        salutationId: personalInformationData?.salutationsID?.value ? personalInformationData?.salutationsID?.value : personalInformationData?.salutationsID,
        firstName: personalInformationData?.firstNameasInPassport,
        lastName: personalInformationData?.lastNameasInPassport,
        contactNo: personalInformationData?.contactNumber,
        likeUsToAddressYou: personalInformationData?.howWouldYouLikeUsToAddressYou,
        isMale: personalInformationData?.isGenderMale === "male" ? true : false,
        nationalityTypeId: personalInformationData?.nationality,
        countryOfResidenceId: personalInformationData?.countryOfResidence,
        residentStatusId: personalInformationData?.residentStatusId,
        raceId: personalInformationData?.raceId,
        businessEmail: personalInformationData?.businessEmail,
        personalEmail: personalInformationData?.alternateEmail,
        linkedinProfile:personalInformationData?.userName ? personalInformationData?.linkedInProfile + (personalInformationData?.userName).toLowerCase() : null,

        yearsOfProfessionalPracticeInIT: professionalCertificationGeneralData?.yearsofProfessionalPracticeIT,
        yearsOfProfessionalPracticeInIS: professionalCertificationGeneralData?.yearsOfProfessionalPracticeIS,
        professionCodeId: professionalCertificationGeneralData?.professionCodeId?.value ? professionalCertificationGeneralData?.professionCodeId?.value : professionalCertificationGeneralData.professionCodeId,

        nameOfNominator1: ordinaryPathData?.nameofNominator1 ? ordinaryPathData?.nameofNominator1 : null,
        // nameOfNominator1: ordinaryPathData?.nameofNominator1 || null,
        membershipNumberForNominator1: ordinaryPathData?.membershipNumberForNominator1 ? ordinaryPathData?.membershipNumberForNominator1 : null,
        // membershipNumberForNominator1: ordinaryPathData?.membershipNumberForNominator1 || null,
        excoPositionIdForNominator1: membershipTypeId !== 4 ? null : ordinaryPathData.excoPositionIdForNominator1?.value ? ordinaryPathData.excoPositionIdForNominator1?.value : ordinaryPathData.excoPositionIdForNominator1,
        // excoPositionIdForNominator1: ordinaryPathData?.excoPositionIdForNominator1?.value ? ordinaryPathData?.excoPositionIdForNominator1?.value : ordinaryPathData?.excoPositionIdForNominator1,
        excoTermIdForNominator1: membershipTypeId !== 4 ? null : ordinaryPathData?.excoTermIdForNominator1 ? ordinaryPathData?.excoTermIdForNominator1 : null,
        // excoTermIdForNominator1: ordinaryPathData?.excoTermIdForNominator1?.value ? ordinaryPathData?.excoTermIdForNominator1?.value : ordinaryPathData?.excoTermIdForNominator1,
        uploadEndorsement: membershipTypeId !== 4 ? null : ordinaryPathData.uploadEndorsement?.name ? ordinaryPathData.uploadEndorsement.name : ordinaryPathData.uploadEndorsement.fileName,
        // uploadEndorsement: ordinaryPathData?.uploadEndorsement?.name ? ordinaryPathData?.uploadEndorsement.name : ordinaryPathData?.uploadEndorsement,
        nameOfNominator2: ordinaryPathData?.nameofNominator2 ? ordinaryPathData?.nameofNominator2 : null,
        // nameOfNominator2: ordinaryPathData?.nameofNominator2 || null,
        membershipNumberForNominator2: ordinaryPathData?.membershipNumberForNominator2 ? ordinaryPathData?.membershipNumberForNominator2 : null,
        // membershipNumberForNominator2: ordinaryPathData?.membershipNumberForNominator2 || null,
        excoPositionIdForNominator2: membershipTypeId !== 4 ? null : ordinaryPathData.excoPositionIdForNominator2?.value ? ordinaryPathData.excoPositionIdForNominator2?.value : ordinaryPathData.excoPositionIdForNominator2,
        // excoPositionIdForNominator2: ordinaryPathData?.excoPositionIdForNominator2?.value ? ordinaryPathData?.excoPositionIdForNominator2?.value : ordinaryPathData?.excoPositionIdForNominator2,
        excoTermIdForNominator2: membershipTypeId !== 4 ? null : ordinaryPathData?.excoTermIdForNominator1 ? ordinaryPathData?.excoTermIdForNominator1 : null,
        // excoTermIdForNominator2: ordinaryPathData?.excoTermIdForNominator2?.value ? ordinaryPathData?.excoTermIdForNominator2?.value : ordinaryPathData?.excoTermIdForNominator2,
        uploadEndorsement2: membershipTypeId !== 4 ? null : ordinaryPathData.uploadEndorsement2?.name ? ordinaryPathData.uploadEndorsement2?.name : ordinaryPathData.uploadEndorsement2.fileName,
        // uploadEndorsement2: ordinaryPathData?.uploadEndorsement2?.name ? ordinaryPathData?.uploadEndorsement2?.name : ordinaryPathData?.uploadEndorsement2,

        organisationName: employmentInformationData?.organisationName,
        officeNo: employmentInformationData?.officeNo,
        jobFunctionId: employmentInformationData?.jobFunctionId || null,

        jobLevelId: employmentInformationData?.jobLevelId?.value ? employmentInformationData?.jobLevelId?.value : employmentInformationData?.jobLevelId,
        industryCodeId: employmentInformationData?.industryCodeId?.value ? employmentInformationData?.industryCodeId?.value : employmentInformationData?.industryCodeId,
        resumeFileName: employmentInformationData.resumeFileName?.name ?
          employmentInformationData.resumeFileName?.name : employmentInformationData?.resumeFileName?.fileName ?
            employmentInformationData.resumeFileName?.fileName : employmentInformationData.resumeFileName,

        isconvicted: applicationBackgroundData?.isconvicted === "yes" ? true : false,
        convictedFilePath: applicationBackgroundData.convictedFilePath?.name ?
          applicationBackgroundData.convictedFilePath?.name : applicationBackgroundData?.convictedFilePath?.fileName ?
            applicationBackgroundData.convictedFilePath?.fileName : applicationBackgroundData.convictedFilePath,

        isSuspended: applicationBackgroundData?.isSuspended === "yes" ? true : false,
        suspendedFilePath: applicationBackgroundData.suspendedFilePath?.name ?
          applicationBackgroundData.suspendedFilePath?.name : applicationBackgroundData?.suspendedFilePath?.fileName ?
            applicationBackgroundData.suspendedFilePath?.fileName : applicationBackgroundData.suspendedFilePath,

        isBankrupt: applicationBackgroundData?.isBankrupt === "yes" ? true : false,
        bankruptFilePath: applicationBackgroundData.bankruptFilePath?.name ?
          applicationBackgroundData.bankruptFilePath?.name : applicationBackgroundData?.bankruptFilePath?.fileName ?
            applicationBackgroundData.bankruptFilePath?.fileName : applicationBackgroundData.bankruptFilePath,


        isBodyTerminated: applicationBackgroundData?.isBodyTerminated === "yes" ? true : false,
        bodyTerminatedFileName: applicationBackgroundData.bodyTerminatedFileName?.name ?
          applicationBackgroundData.bodyTerminatedFileName?.name : applicationBackgroundData?.bodyTerminatedFileName?.fileName ?
            applicationBackgroundData.bodyTerminatedFileName?.fileName : applicationBackgroundData.bodyTerminatedFileName,

        isBulletin: communicationData?.bulletCheckbox ? communicationData?.bulletCheckbox : false,
        isNewsLetter: communicationData?.newsLetterCheckBox ? communicationData?.newsLetterCheckBox : false,
        isEDM: communicationData?.EDMCheckbox ? communicationData?.EDMCheckbox : false,
        tagId: communicationData.tagId?.join(', '),

        //2 array 
        individualProfessionalCertification: professionalCertificateData,
        individualEducationInformation: edcuationInfoData,
      }
      debugger
      updateIndividualMemberById(request);
    }
    else {
      error("Fill in all required fields")
    }
  }
  // Add save Engagement tracking and notes
  const addEngagement = () => {
    const engagementData = engagementDetailsRef?.current?.getFormData();
    let requestEngagementNotesObj = {
      individualMemberId: individualMemberId,
      individualEngagementId: 0,
      corporateEngagementTypeId: engagementData.engagementType,
      engagementDate: engagementData.engagementDate,
      engagementDescription: engagementData.description,
      engagementRecordedById: loginAdminId,
    }
    addEngagementTrackingAndNotes(requestEngagementNotesObj);
  }
  // Handle clear for file upload button
  const handleClear = (data, name) => {
    if (name === "ProfessionalCertification") {
      //professionalCertificateData.id
    }
    else if (name === "EdcuationInformation") {
      // let temp = { ...edcuationInformationForm }
      // const edcuationInformationData = edcuationInformationRef.current.getFormData();
      // temp.initialState = {
      //   educationInstitutionName: edcuationInformationData.educationInstitutionName,
      //   highestEducationalQualification: edcuationInformationData.highestEducationalQualification,
      //   yearOfComplition: edcuationInformationData.yearOfComplition,
      //   edcuationCertificate: null,
      // }
      // setEdcuationInformationForm({ ...temp })
    }
  }

  const formActionHandler = {
    EDIT: handleEdit,
    DELETE: handeDelete,
    ADD: handleAdd,
    UPDATE: handleUpdate,
    DOWNLOAD: handleDownload,
    CLEAR: handleClear
  }

  const accordionData = () => {
    let accData = [
      {
        id: "accountInformation",
        header: 'Account Information',
        body: <IndividualAccountInformation
          formData={individualAccountInformationForm}
          ref={individualAccountInformationRef}
        />
      },
      {
        id: "engagementTrackingAndNotes",
        header: 'Engagement Tracking And Notes',
        body: <EngagementTrackingAndNotes
          formData={engagementTrackingNotesForm}
          engagementList={engagementTrackingHistory}
          ref={engagementDetailsRef}
          addEngagement={addEngagement} />
      },
      {
        id: "membershipSubscription",
        header: 'Membership Subscription',
        body: <MembershipApplication
          formData={membershipApplicationForm}
          ref={membershipApplicationRef}
          handleFieldChange={handleFieldChnage} />
      },
      {
        id: "personalInformation",
        header: 'Personal Information',
        body: <PersonalInformation
          formData={personalInformationForm}
          ref={personalInformationRef}
          handleNationalityDropdown={handleOnNationalityDropdownChange} />
      },
      {
        id: "professionalCertificationGeneralInformation",
        header: 'Professional Certification (General Information)',
        body: <ProfessionalCertification
          formData={professionalCertificationDataGeneralForm}
          ref={professionalCertificationGeneralRef} />
      },
      {
        id: "professionalCertificationDetails",
        header: 'Professional Certification (Details)',
        body: <ProfessionalCertificationDetails
          formData={professionalCertificationForm}
          ref={professionalCertificationRef}
          editProfessionalCertificate={editProfessionalCertificate}
          ActionHandler={formActionHandler}
          handleOnChangeFileUpload={handleOnChangeFileUpload} />
      },
      {
        id: "educationInformation",
        header: 'Education Information',
        body: <EdcuationInformation
          formData={edcuationInformationForm}
          ref={edcuationInformationRef}
          editEdcuationInformation={editEdcuationInformation}
          ActionHandler={formActionHandler}
          handleOnChangeFileUpload={handleOnChangeFileUpload} />
      },
      {
        id: "employmentInformation",
        header: 'Employment Information',
        body: <EmploymentInformation
          formData={employmentInformationForm}
          ref={employmentInformationRef}
          handleOnChangeFileUpload={handleOnChangeFileUpload}
          handleChangeOnFileDownload={handleDownload}
        />
      },
      {
        id: "forOrdinaryPath3",
        header: 'For Ordinary Path 3',
        body: <OrdinaryPath3
          formData={ordinaryPathForm}
          ref={ordinaryPathFormRef}
          handleOnChangeFileUpload={handleOnChangeFileUpload}
          handleChangeOnFileDownload={handleDownload}
        />
      },
      {
        id: "pointOfContact",
        header: 'Application Background',
        body: <ApplicationBackground
          formData={applicationBackgroundForm}
          ref={applicationBackgroundRef}
          handleOnChangeFileUpload={handleOnChangeFileUpload}
          handleChangeOnFileDownload={handleDownload}
        />
      },
      {
        id: "financialRecord",
        header: 'Financials Record',
        body: <FinancialRecord
          individualMemberId={individualMemberId}
          isIndividualMember={isIndividualMember}
        />
      },
      {
        id: "organisationDetails",
        header: 'Organisation Details',
        body: <OrganisationDetails
        // formData={organisationDetailsForm}
        // ref={organisationDetailsRef}
        />
      },
      {
        id: "communication",
        header: 'Communication',
        body: <Communication
          formData={communicationDetailsForm}
          ref={communicationDetailsRef}
        />
      },
    ];
    return accData;
  }

  useEffect(() => {
    if (getMemberDetailsIsSuccess && getMemberDetailsData) {
      if (getMemberDetailsData.membershipTypeId !== 4) {
        let tabs = accordionData().filter(obj => obj.id !== "forOrdinaryPath3");
        setIndividualTabList([...tabs]);
      }
      else {
        setIndividualTabList(accordionData());
      }
    }
  }, [edcuationInformationForm, professionalCertificationForm, membershipApplicationForm, engagementTrackingHistory]
  )
  // Hanle on field change method
  const handleFieldChnage = (fieldName, data) => {
    if (fieldName === "membershipType" && data?.value === 12) {
      const userData = getCookie('AdminAuthUser');
      let request = {}
      if (userData) {
        request = {
          userName: userData.userName,
          password: "",
          isVerified: true,
          currentDateTimeFromUserSystem: new Date().toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
          })
        };
        let keyName = 'password';
        // validatePassword(request);
        confirmTextWithApi("Confirm", "Enter you admin password to confirm.", "Confirm", "Cancel", true, validatePassword, request, keyName, "isAuthenticated", true)
          .then((result) => {
            if (result === false) {
              setMembershipApplicationFormInitials()
            }
            else if (result) {
              let temp = { ...membershipApplicationForm }
              let tempInitialState = { ...temp.initialState };
              tempInitialState.membershipType = 12;
              tempInitialState.membershipEndDate = new Date(9999, 11, 31);
              temp.initialState = tempInitialState;
              setMembershipApplicationForm(temp);
            }
          }
          )
      }
      else {
        navigate('/login')
      }
    }
  }
  // Reset engagement tracking details
  const resetForm = () => {
    let form = { ...EngagementTrackingNotesData };
    form.initialState = {
      engagementType: 2,
      engagementDate: "",
      description: "",
      recordedBy: 0,
    }
    setEngagementTrackingNotesForm(form);
  }


  return (
    <>
      <div className='common-accordian-view-design'>
        {individualTabList ? <Accordion defaultActiveKey={["0"]} alwaysOpen>
          {individualTabList?.map(item => (
            <Accordion.Item key={item.id} eventKey={item.id}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>{item.body}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
          : <DataLoader />}
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div className="mb-3">
            <Buttons
              buttonText="Back"
              buttonTypeClassName="back-btn"
              onClick={onBackButton}
            />
          </div>
          <div className="mb-3">
            <Buttons
              buttonText="Save"
              buttonTypeClassName="theme-btn"
              onClick={saveButton}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EditIndividualMembersForm;
